










































import { Component, Vue } from 'vue-property-decorator'

// Store
import MasterProgressAchievementsModule from '@/store/modules/master/progress_achievements'
// Components
import AchievementCard from '@/components/views/progress/achievements/AchievementCard.vue'
import ComingSoon from '@/components/views/progress/achievements/ComingSoon.vue'
import ProgressViewAchievementModal from '@/components/modals/progress/ProgressViewAchievementModal.vue'
// Types
import { AchievementResource } from '@/store/types/schema.progress'
import { AchievementResourceWithIndexes } from '@/views/master/progress/ProgressAchievements.vue'

@Component({
  components: {
    AchievementCard,
    ComingSoon,
    ProgressViewAchievementModal,
  },
})
export default class ProgressDashboardAchievements extends Vue {
  private isVisibleAchievementInfo = false
  private currentAchievementIndex = 0
  private isShowComingSoon = true
  private isLoaded = false

  private get currentAchievementInfo (): AchievementResourceWithIndexes {
    return {
      ...this.usersAchievements[this.currentAchievementIndex],
      isNextIndex: this.currentAchievementIndex + 1 < this.usersAchievements.length,
      isPrevIndex: this.currentAchievementIndex - 1 >= 0,
    }
  }

  private get usersAchievements (): AchievementResource[] {
    return MasterProgressAchievementsModule.usersAchievements
  }

  private async mounted () {
    try {
      await MasterProgressAchievementsModule.getUsersAchievements()
      this.isShowComingSoon = false
    } catch (error) {
      this.isShowComingSoon = true
    } finally {
      this.isLoaded = true
    }
  }

  private handleAchievementOpen(index: number) {
    this.isVisibleAchievementInfo = true
    this.currentAchievementIndex = index
  }
}

