


















































import { Component, Prop } from 'vue-property-decorator'

// Store
import { CharacterUpdateRequest, ItemLargeResource, ItemType } from '@/store/types/schema.progress'
import MasterCharacterModule from '@/store/modules/master/character'
// Mixins
import MasterCharacterMixin from '@/mixins/master/progress/MasterCharacterMixin'
// Components
import ProgressCharacterRoom from '@/components/views/progress/character/ProgressCharacter.Room.vue'
import CharacterEditForm from '@/components/forms/progress/CharacterEditForm.vue'
import CharacterEditFormWithTabs from '@/components/forms/progress/CharacterEditFormWithTabs.vue'

@Component({
  components: {
    CharacterEditForm,
    CharacterEditFormWithTabs,
    ProgressCharacterRoom,
  },
})
export default class ProgressCharacterModalEdit extends MasterCharacterMixin {
  @Prop({ required: true })
  private visible!: boolean

  private isMounted = false

  private form: CharacterUpdateRequest = {
    about: '',
    equipments: [],
    username: '',
  }

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
  }

  private get characterSize() {
    if (this.$vuetify.breakpoint.width > 1279) {
      return 'xl'
    } else if (this.$vuetify.breakpoint.width > 767) {
      return 'xs'
    }

    return 'xxs'
  }

  private get currentColorControlled() {
    const currentColor = this.inventory.find(item => {
      return item.type === ItemType.CHARACTER_COLOR && this.form.equipments?.includes(item.uuid)
    })
    if (!currentColor || !currentColor.attributes) return ''

    return currentColor.attributes.color?.primary
  }

  private get neighbors() {
    const keyNameForColor = this.serachNeighbors(this.$vuetify.theme.themes.light, this.currentColorControlled as string)

    return this.$vuetify.theme.themes.light[keyNameForColor[0]]
  }

  private get background() {
    return this.inventory.find(item => {
      return item.type === ItemType.BACKGROUND_COLOR && this.form.equipments?.includes(item.uuid)
    })
  }

  private mapBorderStyle(item: ItemLargeResource) {
    if (item.slug === 'frame_type_rounded') {
      return { ...item, name: 'скругленная', value: '32px' }
    } else if (item.slug === 'frame_type_square') {
      return { ...item, name: 'квадратная', value: '0px' }
    }
  }

  private get borderStyle() {
    const foundBorderStyle = this.inventory.find(item => {
      return item.type === ItemType.FRAME_TYPE && this.form.equipments?.includes(item.uuid)
    })

    return this.mapBorderStyle(foundBorderStyle as ItemLargeResource)
  }

  private get borderColor() {
    return this.inventory.find(item => {
      return item.type === ItemType.FRAME_COLOR && this.form.equipments?.includes(item.uuid)
    })
  }

  private get controlledCharacterColors() {
    return {
      backgroundColor: `#${this.background?.attributes?.color?.primary}`,
      belly: this.neighbors ? (this.neighbors as any)['lighten-4'] : '',
      body: `#${this.currentColorControlled}`,
      borderColor: `#${this.borderColor?.attributes?.color?.primary}`,
      borderStyle: this.borderStyle?.value,
      face: this.neighbors ? (this.neighbors as any)['darken-4'] : '',
      stroke: this.neighbors ? (this.neighbors as any)['lighten-2'] : '',
    }
  }

  private mounted() {
    this.form = {
      ...MasterCharacterModule.character,
      equipments: [...MasterCharacterModule.character.equipments as string[]],
    }
    // ToDo: возможно не нужно
    this.isMounted = true
  }

  private updateForm(form: Required<CharacterUpdateRequest>) {
    this.form = {
      about: form.about,
      equipments: [...form.equipments],
      username: form.username,
    }
  }

  private handleClose() {
    this.$emit('update:visible', false)
  }

  private handleSaveCharacter() {
    this.$emit('save-form', this.form)
    this.$emit('update:visible', false)
  }
}
