

























import { Component, Prop } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'

// Components
import ProgressSubjectChip from '@/components/_uikit/progress/ProgressSubjectChip.vue'
// Mixins
import MasterProgressCourseMixin from '@/mixins/master/progress/MasterProgressCourseMixin'
// Types
import { UserRankResource } from '@/store/types/schema.progress'

@Component({
  components: {
    ProgressSubjectChip,
  },
})
export default class MasterProgressCourseCard extends mixins(MasterProgressCourseMixin) {
  @Prop({ required: true })
  private course!: UserRankResource

  private isInHoverState = false

  private get fullDescription() {
    return this.course.description
  }

  private get description() {
    return this.course.course.name
  }

  private get rank() {
    return this.course.position
  }

  private get subject() {
    return this.course.course.subject
  }

  private get title () {
    return this.course.name
  }

  private get isSpecial () {
    return this.course?.course?.type === 'special' ?? false
  }

  private get icon() {
    return this.course ? this.getRankIcon(this.course.position, this.isSpecial) : ''
  }

  private get isLargeWordInName () {
    return this.title.split(' ').findIndex(word => (word.length > 12)) + 1
  }

  private tagText = this.getTagText(this.rank)
  private descriptionText = this.description
  private descriptionTextClass = 'text-body-1'
  private hideTitle = false

  private onAnimationCallback(callback: () => void) {
    setTimeout(() => {
      callback()
    }, 500 / 100 * 50)
  }

  private hoverBehaviour<T>(beforeHover: T, hoverValue: T): T {
    return !this.isInHoverState ?
      beforeHover :
      hoverValue
  }

  private tagTextOnHover() {
    this.tagText = this.hoverBehaviour(
      this.rank + ' уровень',
      'Посмотреть прогресс',
    )
  }

  private descriptionOnHover() {
    this.descriptionText = this.hoverBehaviour(
      this.description,
      this.fullDescription,
    )
  }

  private descriptionTextClassOnHover() {
    this.descriptionTextClass = this.hoverBehaviour(
      'text-body-1',
      'caption',
    )
  }

  private titleOnHover() {
    this.hideTitle = this.hoverBehaviour(
      false,
      true,
    )
  }

  // Note: вызывается в теплейте, где локально существует hover значение из слота
  private trackHover(hoverState: boolean) {
    this.isInHoverState = hoverState
    this.notifyAllHoverSubscribers()
  }

  private notifyAllHoverSubscribers() {
    this.onAnimationCallback(() => {
      this.tagTextOnHover()
      this.descriptionOnHover()
      this.descriptionTextClassOnHover()
      this.titleOnHover()
    })
  }

  private handleClickOnCourse() {
    this.$router.push({
      name: 'master.progress.course',
      params: {
        courseID: this.course.course.id.toString(),
      },
    })
  }
}
