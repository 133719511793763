import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { VuetifyThemeItem } from 'vuetify/types/services/theme'

// Store
import MasterCharacterModule from '@/store/modules/master/character'
// Types
import { ItemLargeResource, ItemType } from '@/store/types/schema.progress'

@Component
export default class MasterCharacterMixin extends Vue {
  protected get character() {
    return MasterCharacterModule.character
  }  

  protected get inventory() {
    return MasterCharacterModule.inventory
  }
  
  protected get characterItemsFromInventory() {
    const itemsFromInventory: ItemLargeResource[] = []
    this.character.equipments?.forEach(uuid => {
      const characterBackgroundLargeResource = this.inventory.find(item => item.uuid === uuid)
      characterBackgroundLargeResource ? 
        itemsFromInventory.push(characterBackgroundLargeResource)
        : null
    })

    return itemsFromInventory
  }

  protected serachNeighbors = (objectToItterate: any, stringToSearch: string) => {
    let resultKeyPath: Array<string> = []
    Object.entries(objectToItterate).forEach(([key, value]: [string, any]) => {
      if (
        typeof value !== 'object' && 
        value.replace('#', '').toUpperCase() === stringToSearch.toUpperCase()
      ) {
        resultKeyPath = [key]
        return
      } else if (typeof value === 'object') {
        Object.entries(value).forEach(([keyInner, valueInner]: [string, any]) => {
          if (
            typeof valueInner !== 'object' && 
            valueInner.replace('#', '').toUpperCase() === stringToSearch?.toUpperCase()
          ) {
            resultKeyPath = [key, keyInner]
          }
        })
        return 
      }
    })

    return resultKeyPath
  }

  private get colorNeighbors(): VuetifyThemeItem {
    const keyNameForColor = this.serachNeighbors(this.$vuetify.theme.themes.light, this.currentColor as string)

    return this.$vuetify.theme.themes.light[keyNameForColor[0]]
  }

  protected get characterColorsInInventory() {
    return this.inventory.filter(item => item.type === ItemType.CHARACTER_COLOR)
  }

  protected get characterPosesInInventory() {
    return this.inventory.filter(item => item.type === ItemType.CHARACTER_POSE)
  }

  protected get characterBackgroundsInInventory() {
    return this.inventory.filter(item => item.type === ItemType.BACKGROUND_COLOR)
  }

  protected get characterBorderStyles() {
    return this.inventory.filter(item => item.type === ItemType.FRAME_TYPE).map(item => {
      if (item.slug === 'frame_type_rounded') {
        return { ...item, name: 'скругленная', value: '32px' }
      } else if (item.slug === 'frame_type_square') {
        return { ...item, name: 'квадратная', value: '0px' }
      }
    })
  }

  protected get characterBorderColors() {
    return this.inventory.filter(item => item.type === ItemType.FRAME_COLOR)
  }

  protected get currentColor() {
    const currentColor = this.characterItemsFromInventory.find(item => item.type === ItemType.CHARACTER_COLOR)
    if (!currentColor || !currentColor.attributes) return

    return currentColor.attributes.color?.primary
  }

  protected get characterColors() {
    return {
      belly: this.colorNeighbors ? (this.colorNeighbors as any)['lighten-4'] : '',
      body: `#${this.currentColor}`,
      face: this.colorNeighbors ? (this.colorNeighbors as any)['darken-4'] : '',
      stroke: this.colorNeighbors ? (this.colorNeighbors as any)['lighten-2'] : '',
    }
  }

  protected get frameType() {
    const characterItem = this.characterItemsFromInventory.find(item => item.type === ItemType.FRAME_TYPE)

    if (!characterItem) return

    switch(characterItem?.slug) {
    case 'frame_type_rounded':
      return '32px'
    default:
      return '0px'
    }
  }

  protected get frameColor() {
    const characterItem = this.characterItemsFromInventory.find(item => item.type === ItemType.FRAME_COLOR)

    if (!characterItem) return

    switch(characterItem?.slug) {
    case 'frame_color_dynamic':
      return `#${characterItem.attributes?.color?.primary}`
    default:
      return 'red'
    }
  }

  protected get backgroundColor() {
    const characterItem = this.characterItemsFromInventory.find(item => item.type === ItemType.BACKGROUND_COLOR)

    if (!characterItem) return

    switch(characterItem?.slug) {
    case 'background_color_dynamic':
      return `#${characterItem.attributes?.color?.primary}`
    default:
      return 'yellow'
    }
  }

  // private created() {
  //   this.localCharacter = {
  //     ...this.character,
  //   }
  //   this.$emit('local-character-updated', this.localCharacter)
  // }
  
  // protected changeCharacter(field: ICharacterField | string, payload: ICharacterValue) {
  //   MasterCharacterModule.setCharacterConfig({
  //     field,
  //     payload,
  //   })
  // }

  // protected scopedChangeCharacter(field: ICharacterField | string, payload: ICharacterValue) {
  //   const fields = field.split('.')
  //   const isTreeRequired = fields.length > 1

  //   this.localCharacter = {
  //     ...this.localCharacter,
  //     ...(isTreeRequired ? deepObjectChange(this.localCharacter, fields, payload) : { [field]: payload }),
  //   }
  //   this.$emit('local-character-updated', this.localCharacter)
  // }
}
