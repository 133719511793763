





import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class EmojiIcon extends Vue {
  private name = 'tongue-wink'

  private emojiNames = ['tongue-wink', 'kid', 'wink']

  private innerValues = {
    kid:
      `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
        <path fill="#56C0AD" d="M16.665 16.667a1.667 1.667 0 1 0-3.333 0 1.667 1.667 0 0 0 3.333 0Zm7.5 6.766a1.668 1.668 0 0 0-2.283.6 2.166 2.166 0 0 1-3.767 0 1.667 1.667 0 0 0-2.283-.6 1.667 1.667 0 0 0-.617 2.267 5.517 5.517 0 0 0 9.567 0 1.667 1.667 0 0 0-.617-2.267ZM25 15a1.667 1.667 0 1 0 0 3.333 1.667 1.667 0 0 0 0-3.333Zm-5-11.666a16.667 16.667 0 1 0 0 33.333 16.667 16.667 0 0 0 0-33.334Zm0 30a13.333 13.333 0 0 1-5-25.717 5.012 5.012 0 0 0 0 .717 5 5 0 0 0 5 5 1.666 1.666 0 1 0 0-3.334 1.667 1.667 0 0 1 0-3.333 13.333 13.333 0 0 1 0 26.666Z"/>
      </svg>`,
    'tongue-wink':
      `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
        <path fill="#FFA552" d="M19.999 3.333a16.667 16.667 0 1 0 0 33.334 16.667 16.667 0 0 0 0-33.334Zm0 30a13.333 13.333 0 1 1 0-26.666 13.333 13.333 0 0 1 0 26.666Zm9.366-18.116a5.133 5.133 0 0 0-7.066 0 1.667 1.667 0 0 0 2.35 2.35 1.666 1.666 0 0 1 2.366 0 1.667 1.667 0 0 0 2.35 0 1.667 1.667 0 0 0 0-2.35ZM15 18.334a1.667 1.667 0 1 0 0-3.335 1.667 1.667 0 0 0 0 3.335Zm10 3.333h-10a1.667 1.667 0 0 0 0 3.333 5 5 0 0 0 10 0 1.666 1.666 0 1 0 0-3.333Zm-5 5A1.667 1.667 0 0 1 18.332 25h3.333A1.667 1.667 0 0 1 20 26.667Z"/>
      </svg>`,
    wink:
      `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
        <path fill="#6B9EFF" d="M14.999 18.334a1.667 1.667 0 1 0 0-3.335 1.667 1.667 0 0 0 0 3.335Zm8.6 2.016a1.668 1.668 0 0 0 1.183.483 1.667 1.667 0 0 0 1.183-2.85l-1.316-1.316 1.316-1.317a1.673 1.673 0 0 0-2.366-2.367l-2.5 2.5a1.666 1.666 0 0 0 0 2.367l2.5 2.5Zm-3.6-17.017a16.667 16.667 0 1 0 0 33.334 16.667 16.667 0 0 0 0-33.334Zm0 30a13.333 13.333 0 1 1 0-26.666 13.333 13.333 0 0 1 0 26.666Zm3.933-9.616a6.266 6.266 0 0 1-7.867 0 1.669 1.669 0 0 0-2.133 2.567 9.467 9.467 0 0 0 12.133 0 1.669 1.669 0 0 0-2.133-2.567Z"/>
      </svg>`,
  }

  private mounted () {
    this.name = this.emojiNames[Math.floor(Math.random() * 2)]
  }
}
