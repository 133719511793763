























import { Component } from 'vue-property-decorator'

// Components
import ProgressCharacterModalEdit from '@/components/modals/progress/ProgressCharacterModal.Edit.vue'
import ProgressDashboardAchievements from '@/components/views/progress/ProgressDashboard.Achievements.vue'
import ProgressDashboardCharacter from '@/components/views/progress/ProgressDashboard.Character.vue'
import ProgressDashboardLevels from '@/components/views/progress/ProgressDashboard.Levels.vue'
// Types
import { CharacterUpdateRequest } from '@/store/types/schema.progress'
// Store
import MasterCharacterModule from '@/store/modules/master/character'
// Mixins
import NotifyMixin from '@/mixins/NotifyMixin'

@Component({
  components: {
    ProgressCharacterModalEdit,
    ProgressDashboardAchievements,
    ProgressDashboardCharacter,
    ProgressDashboardLevels,
  },
})
export default class ProgressDashboard extends NotifyMixin {
  private goToAchievements () {
    this.$router.push({ name: 'master.progress.achievements' })
  }

  private visible = false

  private handleSave(character: CharacterUpdateRequest) {
    MasterCharacterModule.saveCharacter(character)
      .then(() => this.notifySuccess('Персонаж успешно изменен!'))
      .catch(this.notifyError)
  }

  private handleEditOpen() {
    this.visible = true
  }
}
