


















































































import { mixins } from 'vue-class-component'
import { Component, Prop } from 'vue-property-decorator'

/**
 * Note: В будущем вероятно тут будет больше логики для кастомизации пресонажа
 */

// Mixins
import MasterCharacterMixin from '@/mixins/master/progress/MasterCharacterMixin'

type CharacterSizesType = 'default' | 'xl' | 'xs' | 'xxs'

@Component
export default class ProgressCharacter extends mixins(MasterCharacterMixin) {
  @Prop({ required: true })
  private bodyColor!: `#${string}`

  @Prop({ required: true })
  private bodyStroke!: `#${string}`

  @Prop({ required: true })
  private bellyColor!: `#${string}`

  @Prop({ required: true })
  private faceColor!: `#${string}`

  @Prop({ default: 'default' })
  private size!: CharacterSizesType

  private get characterSize(): number {
    const sizeMapInPixels: Record<CharacterSizesType, number> = {
      default: 300,
      xl: 400,
      xs: 200,
      xxs: 100,
    }
    if (this.size in sizeMapInPixels) {
      return sizeMapInPixels[this.size]
    }

    return sizeMapInPixels.default
  }

  private get characterClasses(): string[] {
    /* eslint-disable */
    const appliedClasses = [],
          characterSizeBaseClass = 'character-size'
    /* eslint-enabled */

    // Note: классы для размера стандартных пропорций персонажа - разные размеры
    // по разному могут адаптивиться
    switch(this.size) {
      case 'xxs':
        appliedClasses.push(`${characterSizeBaseClass}__xxs`)
        break
      case 'xs':
        appliedClasses.push(`${characterSizeBaseClass}__xs`)
        break
      case 'xl':
        appliedClasses.push(`${characterSizeBaseClass}__xl`)
        break
      default:
        appliedClasses.push(`${characterSizeBaseClass}__default`)
        break
    }

    return appliedClasses
  }

  private get viewbox() {
    return '0 0 300 300'
  }
}
