










import { Component, Vue } from 'vue-property-decorator'

// Components
import ProgressDashboardNoCourses from '@/components/views/progress/levels/ProgressDashboard.NoCourses.vue'
// Mixins
import ProgressDashboardCourses from '@/components/views/progress/levels/ProgressDashboard.Courses.vue'
import MasterProgressModule from '@/store/modules/master/progress'
import { UserRankResource } from '@/store/types/schema.progress'
import MasterProgressWithCharacterModule from '@/store/modules/master/progress_course'

@Component({
  components: {
    ProgressDashboardCourses,
    ProgressDashboardNoCourses,
  },
})
export default class ProgressDashboardLevels extends Vue {
  private get coursesRanks(): UserRankResource[] {
    return MasterProgressModule.coursesRanks
  }

  private get isHasCourses(): boolean {
    return MasterProgressWithCharacterModule.isHasCourses
  }

  private get noCourses() {
    return this.coursesRanks.length === 0
  }

  private mounted () {
    MasterProgressWithCharacterModule.fetchProgressWithCharacter()
  }

  // private get singleCourseHasNotBeenStarted() {
  //   return this.coursesRanks.every(item => !item.hasStarted)
  // }

  // private get courseRanks() {
  // return this.coursesRanks.every(item => !item.hasStarted)
  // }
}
