import { render, staticRenderFns } from "./ProgressDashboard.Character.vue?vue&type=template&id=540da354&scoped=true&lang=pug&"
import script from "./ProgressDashboard.Character.vue?vue&type=script&lang=ts&"
export * from "./ProgressDashboard.Character.vue?vue&type=script&lang=ts&"
import style0 from "./ProgressDashboard.Character.vue?vue&type=style&index=0&id=540da354&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "540da354",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VCardText})
