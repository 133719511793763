











import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'

// Mixins
import MasterCharacterMixin from '@/mixins/master/progress/MasterCharacterMixin'
// Components
import ProgressCharacter from '@/components/views/progress/character/ProgressCharacter.vue'
import ProgressCharacterRoom from '@/components/views/progress/character/ProgressCharacter.Room.vue'
// import CharacterInspirationBlock from '@/components/views/progress/character/CharacterInspirationBlock.vue'
import MotivationalPhrase from '@/components/views/progress/character/MotivationalPhrase.vue'
import MasterCharacterModule from '@/store/modules/master/character'
import NotifyMixin from '@/mixins/NotifyMixin'

@Component({
  components: {
    // CharacterInspirationBlock,
    MotivationalPhrase,
    ProgressCharacter,
    ProgressCharacterRoom,
  },
})
export default class ProgressDashboardCharacter extends mixins(MasterCharacterMixin, NotifyMixin) {
  private get characterSize() {
    if (this.$vuetify.breakpoint.width > 1279) {
      return 'default'
    } else if (this.$vuetify.breakpoint.width > 767) {
      return 'xs'
    }

    return 'xxs'
  }

  private get partingWordText() {
    return MasterCharacterModule.partingWord.text
  }

  private handleEditCharacter() {
    this.$emit('open-edit-modal')
  }

  private mounted() {
    if (!this.partingWordText)
      this.fetchPhrase()
  }

  private fetchPhrase(getRandom = false) {
    MasterCharacterModule.fetchPartingWord({ getRandom })
      .catch(this.notifyError)
  }
}
