
















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  // LinkTabs
})
export default class ProgressDashboardNoCourses extends Vue {
  @Prop({ default: false })
  private singleCourseNotStarted!: boolean

  private hendleGoToCoursesClick() {
    if (this.singleCourseNotStarted) {
      try {
        this.$metrika.reachGoal('my_courses')
      } catch {
        // eslint-disable-next-line
        console.error('Отправка метрики заблокирована расширением браузера')
      }
    }
    const name = this.singleCourseNotStarted ?
      'master.courses.my' :
      'master.store'

    this.$router.push({ name })
  }
}
