





























import { Component, Vue } from 'vue-property-decorator'

// components
import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
@Component({
  components: { ButtonArrow },
})
export default class MotivationalPhrase extends Vue {
  private fetchPhrase() {
    this.$emit('fetchPhrase', true)
    try {
      this.$metrika.reachGoal('want_more')
    } catch {
      // eslint-disable-next-line
      console.error('Отправка метрики заблокирована расширением браузера')
    }
  }
}
