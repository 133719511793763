












import { Component, Vue, Prop } from 'vue-property-decorator'

// Components
import MasterProgressCourseCard from '@/components/cards/MasterProgressCourseCard.vue'
import { UserRankResource } from '@/store/types/schema.progress'

@Component({
  components: {
    MasterProgressCourseCard,
  },
})
export default class ProgressDashboardCourses extends Vue {
  @Prop({ default: () => ([]) })
  private courses!: UserRankResource[]
}
