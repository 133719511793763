



















import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import EmojiIcon from '@/components/_uikit/icons/EmojiIcon.vue'

@Component({
  components: {
    EmojiIcon,
  },
})
export default class ComingSoon extends Vue {}
