











































































































import { mixins } from 'vue-class-component'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// Store
import { CharacterUpdateRequest, ItemLargeResource, ItemType } from '@/store/types/schema.progress'
// Mixins
import MasterCharacterMixin from '@/mixins/master/progress/MasterCharacterMixin'
// Components
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import OptionWithMark from '@/components/_uikit/controls/OptionWithMark.vue'

@Component({
  components: {
    OptionWithMark,
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class CharacterEditFormWithTabs extends mixins(MasterCharacterMixin) {
  @Prop({ required: true })
  formProp!: Required<CharacterUpdateRequest>

  private currentItem = 0

  // Note: было бы хорошо получать с бэка, подумать в эту сторону
  private equipmentKeys = [
    'character_color',
    'character_pose',
    'background_color',
    'frame_type',
    'frame_color',
  ]

  private innerForm: Required<CharacterUpdateRequest> = {
    about: '',
    equipments: [],
    username: '',
  }

  private mounted() {
    this.innerForm = {
      about: this.formProp.about,
      equipments: [...this.formProp.equipments],
      username: this.formProp.username,
    }
  }

  private isMarkedType(uuid: string) {
    return this.innerForm.equipments?.includes(uuid)
  }

  private withBorder(item: ItemLargeResource) {
    return item.type === ItemType.BACKGROUND_COLOR &&
      item.attributes?.color?.primary.toUpperCase() === 'FFFFFF'
  }

  private updateEquipment(type: ItemType, uuid: string) {
    const foundItemInInventory = this.inventory.find(item => {
      return item.type === type && this.innerForm.equipments?.includes(item.uuid)
    })

    if (foundItemInInventory) {
      const foundIndex = this.innerForm.equipments?.findIndex(item => item === foundItemInInventory.uuid) as number
      this.innerForm.equipments?.splice(foundIndex, 1, uuid)
    } else {
      this.innerForm.equipments?.push(uuid)
    }
  }

  private getAttribute(inventoryItem: ItemLargeResource) {
    if (inventoryItem.attributes && inventoryItem.attributes.color) {
      return `#${inventoryItem.attributes.color.primary}`
    }
    return ''
  }

  @Watch('innerForm', { deep: true })
  observeInnerFormState(
    state: Required<CharacterUpdateRequest>,
  ) {
    this.$emit('update-form', state)
  }
}
