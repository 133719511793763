













import { mixins } from 'vue-class-component'
import { Component, Prop } from 'vue-property-decorator'

// Components
import ProgressCharacter from '@/components/views/progress/character/ProgressCharacter.vue'
// Mixins
import MasterCharacterMixin from '@/mixins/master/progress/MasterCharacterMixin'

@Component({
  components: {
    ProgressCharacter,
  },
})
export default class ProgressCharacterRoom extends mixins(MasterCharacterMixin) {
  @Prop({ default: 'default' })
  private characterSize!: string

  @Prop({ default: null })
  private controlledCharacterColors!: {
    belly: string,
    body: string,
    face: string,
    stroke: string,
    backgroundColor: string,
    borderStyle: string,
    borderColor: string,
  }

  private get colors() {
    return this.controlledCharacterColors ? this.controlledCharacterColors : this.characterColors
  }
}
